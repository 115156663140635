import ActiveUsersModal from "components/Modals/statsModals/ActiveUsersModal";
import RevenueGeneratedModal from "components/Modals/statsModals/RevenueGeneratedModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { getCountryRevenueAction } from "store/actions/dashboardActions";
import { getStats } from "store/actions/dashboardActions";

const Header = () => {
  const history = useHistory();
  const { stats, getStatsLoader } = useSelector((state) => state.dashboard);
  const [activeUserModal, setActiveUserModal] = useState(false);
  const toggleActiveUsers = () => setActiveUserModal(!activeUserModal);

  const [revenueModal, setRevenueModal] = useState(false);
  const toggleRevenue = () => setRevenueModal(!revenueModal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStats());
    dispatch(getCountryRevenueAction());
  }, []);

  return (
    <>
      <ActiveUsersModal modal={activeUserModal} toggle={toggleActiveUsers} />
      <RevenueGeneratedModal modal={revenueModal} toggle={toggleRevenue} />
      <div className="header bg-gradient-tealpot pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0 cursor-pointer"
                  onClick={() => {
                    toggleActiveUsers();
                  }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Active Users
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {!getStatsLoader ? (
                            stats && stats?.activeUsers
                          ) : (
                            <Spinner size="sm" />
                          )}
                        </span>
                      </div>
                      <div>
                        <i className="fa fa-eye"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0 cursor-pointer"
                  onClick={() => {
                    toggleRevenue();
                  }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Revenue Generated
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {!getStatsLoader ? (
                            stats && stats?.revenueGenerated?.toFixed(2)
                          ) : (
                            <Spinner size="sm" />
                          )}
                        </span>
                      </div>
                      <div>
                        <i className="fa fa-eye"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0 cursor-pointer"
                  onClick={() => {
                    history.push("/admin/orders", { filter: "inprogress" });
                  }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Ongoing Orders
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {!getStatsLoader ? (
                            stats && stats?.activeOrders
                          ) : (
                            <Spinner size="sm" />
                          )}
                        </span>
                      </div>
                      <div>
                        <i className="fa fa-eye"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  className="card-stats mb-4 mb-xl-0 cursor-pointer"
                  onClick={() => {
                    history.push("/admin/orders", { filter: "completed" });
                  }}
                >
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Completed Orders
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {!getStatsLoader ? (
                            stats && stats?.completedOrders
                          ) : (
                            <Spinner size="sm" />
                          )}
                        </span>
                      </div>
                      <div>
                        <i className="fa fa-eye"></i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
