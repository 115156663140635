import React, { useEffect, useRef, useState } from "react";
import { Input, FormGroup, Spinner, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const DropdownComponent = ({
  list = [],
  value = "",
  onChange = () => {},
  placeholder = "-- Select Category --",
  isOpen = false,
  toggleDropdown = () => {},
  canPaginate = false,
  onSearch = () => {},
  onLoadMore = () => {},
  clearSearch = () => {},
  isLoading = false,
}) => {
  const ref = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  const filteredList = searchQuery
    ? list.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : list;

  const handleSelect = (item) => {
    setSelectedValue(item.label);
    onChange(item);
    toggleDropdown();
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query === "") {
      if (isSearched) {
        clearSearch();
        setIsSearched(false);
      }
    }
    const hasResults = list.some((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );
    if (!hasResults) {
      onSearch(query);
      setIsSearched(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        if (isOpen) {
          toggleDropdown();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div ref={ref} className="position-relative">
      <FormGroup>
        <div
          className="form-control d-flex align-items-center justify-content-between border-1 rounded p-2"
          role="button"
          onClick={toggleDropdown}
        >
          <span>{selectedValue || placeholder}</span>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </div>
      </FormGroup>
      {isOpen && (
        <div
          className="dropdown-menu show w-100 border shadow rounded"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          <div className="px-2">
          <Input
            type="text"
            placeholder="Search..."
            className="form-control mb-2"
            value={searchQuery}
            onChange={handleSearch}
            disabled={isLoading}
          />
          </div>
          {filteredList.length > 0 ? (
            filteredList.map((item, idx) => (
              <div
                key={`item-${idx}`}
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleSelect(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className="dropdown-item text-muted">
              {isLoading ? "Loading..." : "No results found"}
            </div>
          )}
          {canPaginate && !searchQuery && (
            <Button
            color="primary"
            className="w-100 rounded-0 py-1"
              disabled={isLoading}
              onClick={onLoadMore}
            >
              {isLoading ? <Spinner size="sm" /> : "Load More"}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownComponent;
