import { Modal, ModalHeader, ModalBody, Col, Table } from "reactstrap";

const ApplicantsModal = ({ modal, toggle, data }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className="pb-0 pt-2 px-2"></ModalHeader>
      <ModalBody className="pt-0">
        <h2 className="text-center mb-4">Applicants</h2>
        <div className="d-flex flex-column align-items-center">
          <Col className="p-0">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <div key={index} className="mb-3">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone no</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {item?.seller?.firstName} {item?.seller?.lastName}
                        </td>
                        <td>{item?.seller?.email}</td>
                        <td>{item?.seller?.phoneNumber}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ))
            ) : (
              <p className="m-0">No one has applied for this work order yet</p>
            )}
          </Col>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ApplicantsModal;
