import { convertToHTML } from "draft-convert";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { FaLink } from "react-icons/fa6";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader";
import { useHistory } from "react-router-dom";
import { addNewBlog, createBlog } from "store/actions/blogsAction";
import { toast } from "react-toastify";
import { getCategoriesAction } from "store/actions/categoriesAction";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import Select from "react-select";
import ImagePicker from "components/ImagePicker";

const AddBlog = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { allSubCategories, allCategories } = useSelector(
    (state) => state.category
  );

  const { addBlogLoader } = useSelector((state) => state.blogs);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [blogId, setBlogId] = useState("");
  const [newImage, setNewImage] = useState(null);

  const [blogData, setBlogData] = useState({
    title: "",
    content: "",
    category: "",
    file: "",
    metaTitle: "",
    metaDescription: "",
    altText: "",
    blogUrl: "",
    scheduleTime: "",
  });

  const domain = () => {
    const url = window.location.href;
    let domainUrl;
    if (url.includes("tealpot-admin.web.app") || url.includes("localhost")) {
      domainUrl = "staging.tealpot.com/blogs/detail";
    } else if (url.includes("tealpot-admin-live.web.app") || url.includes("admin.tealpot.com")) {
      domainUrl = "tealpot.com/blogs/detail";
    }
    return domainUrl;
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setBlogData((prevData) => ({
      ...prevData,
      content: currentContentAsHTML,
    }));
  };

  const handleBlogDataChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "featuredImgURL" || name === "getMediaURL") {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else if (name === "blogUrl") {
      setBlogData((prevData) => ({
        ...prevData,
        blogUrl: value.replace(/[^a-zA-Z-]/g, ""),
      }));
    } else {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setBlogData((prevData) => ({
      ...prevData,
      category: selectedOption.value,
    }));
    setSelectedValues([]);
    dispatch(getSubCategoriesAction(selectedOption.value));
  };

  const onSelect = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const convertToUnixTime = (timestamp) => {
    if (!timestamp) return null;
    const dateObject = new Date(timestamp);
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid timestamp format");
    }
    return Math.floor(dateObject.getTime() / 1000);
  };

  const handleSubmit = () => {
    const minTitleLength = 40;
    if (blogData.title.trim().length < minTitleLength) {
      toast.error("Title should be minimum of 40 charachters");
      return;
    }
    if (
      !blogData.title ||
      !blogData.content ||
      !blogData.category ||
      !blogData.file ||
      !blogData.altText ||
      !blogData.metaTitle ||
      !blogData.metaDescription
      // ||
      // selectedValues.length === 0
    ) {
      toast.error("Please fill all the required fields");
      return;
    }
    if (!blogId) {
      toast.error("blog id is not generated");
      return;
    }
    const temp = {
      ...blogData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    const payload = {
      id: blogId,
      title: temp.title,
      description: temp.content,
      category: temp.category,
      subCategory: selectedValues?.map((item) => item?._id) || "",
      file: temp.file,
      metaTitle: temp.metaTitle,
      metaDescription: temp.metaDescription,
      altText: temp.altText,
      blogUrl: temp.blogUrl
        ? `tealpot.com/blogs/detail/${blogId}/${temp.blogUrl}`
        : `tealpot.com/blogs/detail/${blogId}`,
      scheduleTime: convertToUnixTime(temp.scheduleTime),
    };
    dispatch(
      addNewBlog(payload, () => {
        history.push("/admin/blogs");
        toast.success("Blog added Successfully");
      })
    );
  };

  const embedVideoCallBack = (iframeHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = iframeHtml.trim();
    const iframeElement = tempDiv.querySelector("iframe");
    if (iframeElement) {
      const src = iframeElement.getAttribute("src");
      const cleanSrc = src.split("?")[0];
      return cleanSrc;
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(getSubCategoriesAction());
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      createBlog((id) => {
        setBlogId(id);
      })
    );
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto">Title</Label>
                    <Input
                      name="title"
                      className="form-controll_title"
                      value={blogData.title}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                    {blogData.title.trim().length < 40 && (
                      <h6 className="text-muted">
                        Title must be at least 40 characters long.
                      </h6>
                    )}
                  </Col>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto">Category</Label>
                    <Select
                      name="category"
                      value={blogData?.category?.label}
                      options={allCategories.map((category) => ({
                        value: category._id,
                        label: category.name,
                        key: category._id,
                      }))}
                      onChange={handleCategoryChange}
                      required
                    />
                  </Col>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto ">Sub Categories</Label>
                    <Multiselect
                      options={allSubCategories}
                      selectedValues={selectedValues}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      // required
                    />
                  </Col>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto ">Schedule Time</Label>
                    <Input
                      name="scheduleTime"
                      type="datetime-local"
                      value={blogData.scheduleTime}
                      min={new Date().toISOString().split("T")[0] + "T00:00"}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                  </Col>
                  <Col md="8" className="my-2">
                    <Label className="mb-0 my-auto ">Blog URL</Label>
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex border rounded overflow-hidden w-100">
                        <Input
                          className="border-0 rounded-0"
                          value={`${domain()}/${blogId}/`}
                          readOnly
                          disabled
                        />
                        <Input
                          className="border-0"
                          name="blogUrl"
                          value={blogData.blogUrl}
                          onChange={(e) => {
                            handleBlogDataChange(e);
                          }}
                        />
                      </div>
                      <Button
                        color="primary"
                        className="ml-1 p-2"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            blogData.blogUrl
                              ? `${domain()}/${blogId}/${blogData.blogUrl}`
                              : `${domain()}/${blogId}`
                          );
                          toast.success("Copied to clipboard");
                        }}
                      >
                        <FaLink size={20} />
                      </Button>
                    </div>
                  </Col>
                  <Col md="12" className="my-2">
                    <Label className="mb-0 my-auto ">Meta Title</Label>
                    <Input
                      name="metaTitle"
                      value={blogData.metaTitle}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                  </Col>
                  <Col md="12" className="my-2">
                    <Label className="mb-0 my-auto ">Meta Description</Label>
                    <Input
                      type="textarea"
                      rows={3}
                      name="metaDescription"
                      value={blogData.metaDescription}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                  </Col>

                  <div className="mx-3 mt-2 mb-2">
                    <ImagePicker
                      image={blogData.file}
                      onImageChange={(newFile) => {
                        setBlogData((prevData) => ({
                          ...prevData,
                          file: newFile,
                        }));
                      }}
                    />
                    <Input
                      name="altText"
                      className="mt-1"
                      placeholder="Type Alt Text"
                      value={blogData.altText}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                  </div>

                  <Col md={12} className="mt-3">
                    <Editor
                      editorState={editorState}
                      editorStyle={{
                        border: "2px solid #C0C0C0",
                      }}
                      toolbar={{
                        embedded: {
                          embedCallback: embedVideoCallBack,
                          defaultSize: {
                            height: "auto",
                            width: "auto",
                          },
                        },
                        fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                            "Barlow",
                          ],
                        },
                      }}
                      onEditorStateChange={handleEditorChange}
                    />
                  </Col>
                </Row>

                {/* <Row>
                    <Col>
                      <div>
                        <Row style={{ display: "flex", marginTop: "20px" }}>
                          {previewImages.map((preview, index) => (
                            <Col
                              key={index}
                              md={2}
                              style={{ marginRight: "10px" }}
                            >
                              <img
                                src={preview}
                                alt={`Preview ${index}`}
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => handleRemoveImage(index)}
                                style={{
                                  position: "absolute",
                                  top: "0%",
                                  right: "0%",
                                }}
                              >
                                X
                              </Button>
                            </Col>
                          ))}
                        </Row>
                        <div className="mt-3 form-group">
                          <FormGroup>
                            <Label for="exampleFile">Upload Files</Label>
                            <Input
                              type="file"
                              name="attachments"
                              multiple
                              onChange={handleFileChange}
                              id="exampleFile"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row> */}

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={
                        addBlogLoader || !blogData.title || !blogData.category
                        // ||
                        // selectedValues.length === 0
                      }
                    >
                      {addBlogLoader ? (
                        <Spinner
                          className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                          size="sm"
                          color="white"
                          disabled={addBlogLoader}
                        />
                      ) : (
                        "SAVE CHANGES"
                      )}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBlog;
