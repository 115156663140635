import AsBuyerTab from "components/AsBuyerTab";
import AsSellerTab from "components/AsSellerTab";
import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardHeader,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getUserOrders } from "store/actions/dashboardActions";
import { getUserCharts } from "store/actions/dashboardActions";
import { getUserEarnings } from "store/actions/dashboardActions";
import { getSingleUserAction } from "store/actions/usersAction";

const SingleUserDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { singleUser } = useSelector((state) => state.users);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");

  const [viewAsSeller, setViewAsSeller] = useState(true);
  const [user, setUser] = useState();
  const handleViewChange = (isSeller) => {
    setViewAsSeller(isSeller);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getSingleUserAction(id));
  }, []);

  useEffect(() => {
    if (singleUser) {
      setUser(singleUser);
      setViewAsSeller(singleUser?.role === "service-provider" ? true : false);
    }
  }, [singleUser]);
  useEffect(() => {
    const role = !viewAsSeller ? "buyer" : "service-provider";
    dispatch(getUserEarnings(id, role));
    dispatch(getUserCharts(id, role));
    // dispatch(getUserOrders(id, role));
  }, [id, viewAsSeller, dispatch]);
  useEffect(() => {
    const role = !viewAsSeller ? "buyer" : "service-provider";
    let payload = {
      id,
      role,
      page: currentPage,
      status: status,
    };
    dispatch(getUserOrders(payload));
  }, [id, viewAsSeller, status, currentPage, dispatch]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3
                  className="mb-0 cursor-pointer text-sm"
                  onClick={handleGoBack}
                >
                  <i class="fas fa-chevron-left"></i> User Details
                </h3>
              </CardHeader>
              {/* <div className="ml-2 ml-lg-4 d-flex align-items-center">
                <FormGroup check>
                  <Label check className={`cursor-pointer`}>
                    <Input
                      type="radio"
                      name="viewOption"
                      checked={viewAsSeller}
                      // checked={
                      //   user?.organization === false
                      //     ? viewAsSeller
                      //     : !viewAsSeller
                      // }
                      // disabled={user?.organization === false ? false : true}
                      onChange={() => handleViewChange(true)}
                    />{" "}
                    View As Seller
                  </Label>
                </FormGroup>
                <FormGroup check className="mx-2 mx-lg-3">
                  <Label check className="cursor-pointer">
                    <Input
                      type="radio"
                      name="viewOption"
                      checked={!viewAsSeller}
                      // checked={
                      //   user?.organization === false
                      //     ? !viewAsSeller
                      //     : viewAsSeller
                      // }
                      onChange={() => handleViewChange(false)}
                    />{" "}
                    View As Buyer
                  </Label>
                </FormGroup>
              </div> */}
              <div className="p-2 p-lg-5">
                {viewAsSeller ? (
                  <AsSellerTab
                    user={user}
                    setCurrentPage={setCurrentPage}
                    setStatus={setStatus}
                  />
                ) : (
                  <AsBuyerTab user={user} />
                )}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SingleUserDetails;
