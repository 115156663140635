/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const WORK_ORDER = "job";

export default {
  getWorkOrder(page, limit) {
    return Repository.get(`${WORK_ORDER}/admin?page=${page}&limit=${limit}`);
  },
  deleteWorkOrder(id, payload) {
    return Repository.put(`${WORK_ORDER}/admin/${id}`, payload);
  },
  searchWorkOrder(payload, page, limit) {
    return Repository.post(
      `${WORK_ORDER}/search/admin?page=${page}&limit=${limit}`,
      payload
    );
  },
  filterWorkOrder(payload, page, limit) {
    return Repository.post(
      `${WORK_ORDER}/filter/admin?page=${page}&limit=${limit}`,
      payload
    );
  },
  getApplicantsforWorkOrder(id) {
    return Repository.get(`/offer/job/${id}`);
  },
};
