const initState = {
  allWorkOrders: [],
  getWorkOrderLoader: false,
  deleteWorkOrderLoader: false,
  totalPages: null,
  applicants: [],
  applicantsloader: false,
};

const workOrderReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_WORK_ORDER":
      return {
        ...state,
        allWorkOrders: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_WORK_ORDER_LOADER":
      return {
        ...state,
        getWorkOrderLoader: payload,
      };
    case "DELETE_WORK_ORDER_LOADER":
      return {
        ...state,
        deleteWorkOrderLoader: payload,
      };
    case "GET_WORK_ORDER_APPLICANTS":
      return {
        ...state,
        applicants: payload,
      };
    case "APPLICANTS_LOADER":
      return {
        ...state,
        applicantsloader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default workOrderReducer;
