import OnlyHeader from "components/Headers/OnlyHeader";
import ViewOrderActivity from "components/Modals/orderModals/ViewOrderActivity";
import ViewOrderChatModal from "components/Modals/orderModals/ViewOrderChatModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { orderSearchByIdAction } from "store/actions/ordersAction";
import { getAllOrdersAction } from "store/actions/ordersAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Orders = () => {
  const { state } = useLocation();
  const params = state?.filter ? state?.filter : "";
  console.log('params: ', params);

  const { allOrders, allOrdersLoader, totalPages } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();
  const [chatModal, setChatModal] = useState(false);

  const chatToggle = () => {
    setChatModal(!chatModal);
  };

  const [modal, setModal] = useState(false);
  const [singleOrder, setSingleOrder] = useState(null);
  const [selectedOption, setSelectedOption] = useState(params);
  const [OrderSearchId, setOrderSearchId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleOnSumbit = () => {
    setCurrentPage(1);
    dispatch(orderSearchByIdAction(OrderSearchId, currentPage));
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getAllOrdersAction(selectedOption, currentPage));
  }, [dispatch, selectedOption, currentPage]);

  const handleClearSearch = () => {
    setOrderSearchId("");
    dispatch(getAllOrdersAction(selectedOption, currentPage));
  };

  return (
    <>
      <ViewOrderActivity
        modal={modal}
        toggle={toggleModal}
        singleOrder={singleOrder}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center align-items-lg-start flex-xl-row">
                  <Col className="d-flex justify-content-between align-items-center col-12 col-xl-5">
                    <h3 className="text-center text-xl-left">Orders</h3>
                    <FormGroup className="mb-xl-0">
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">All Orders</option>
                        <option value="active">Active Orders</option>
                        <option value="inprogress">In Progress Orders</option>
                        <option value="completed">Completed Orders</option>
                        <option value="cancelled">Cancelled Orders</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={OrderSearchId}
                          onChange={(e) => setOrderSearchId(e.target.value)}
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Order ID
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={allOrdersLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={allOrdersLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* <div className="d-lg-flex justify-content-between align-items-center">
                  <h3>Orders</h3>
                  <Row className="align-items-center">
                    <Col lg={5} xs={8} className="pr-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          id="OrderSearch"
                          name="Search"
                          placeholder="Search...."
                          type="text"
                          value={OrderSearchId}
                          onChange={(e) => setOrderSearchId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg={3}
                      xs={4}
                      className="px-lg-0 text-lg-center text-right"
                    >
                      <Button
                        color="primary"
                        className="mb-lg-0 mb-4 px-3"
                        onClick={handleOnSumbit}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col lg={4} xs={12} className="pl-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="">All Orders</option>
                          <option value="active">Active Orders</option>
                          <option value="completed">Completed Orders</option>
                          <option value="pending">Pending Orders</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </div> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Order ID</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Buyer Name</th>
                    <th scope="col">Seller Name</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {!allOrdersLoader ? (
                  <tbody>
                    {allOrders && allOrders.length > 0 ? (
                      allOrders?.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{order?.orderId}</td>
                            <td>{formatTimestamp(order?.createdAt)}</td>
                            <td>{formatTimestamp(order?.updatedAt)}</td>
                            <td>{order?.buyer?.firstName}</td>
                            <td>{order?.seller?.firstName}</td>
                            <td>{order?.job?.city}</td>
                            <td>{order?.job?.state}</td>
                            <td>{order?.totalPrice?.toFixed(2)}</td>
                            <td>
                              {order?.status === "pending" ? (
                                <Badge color="warning">{order.status}</Badge>
                              ) : order?.status === "failed" ? (
                                <Badge color="danger">{order.status}</Badge>
                              ) : (
                                <Badge color="success">{order.status}</Badge>
                              )}
                            </td>
                            <td>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  toggleModal();
                                  setSingleOrder(order);
                                }}
                              >
                                View
                              </Button>
                              {order?.status == "cancelled" && (
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    chatToggle();
                                    setSingleOrder(order);
                                  }}
                                >
                                  View Chat
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No orders found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
        <ViewOrderChatModal
          modal={chatModal}
          toggle={chatToggle}
          order={singleOrder}
        />
      </Container>
    </>
  );
};

export default Orders;
