import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import SuspendAccountModal from "components/Modals/usersModals/SuspendAccountModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { userSearch } from "store/actions/usersAction";
import { activateUserAccount, getUsers } from "store/actions/usersAction";
import UserCSVModal from "components/Modals/usersModals/UserCSVModal";
import { filterUsers } from "store/actions/usersAction";
import { getSales } from "store/actions/salesAction";
import ProvidersModal from "components/Modals/usersModals/ProvidersModal";
import { filterProviders } from "store/actions/usersAction";
import { set } from "store";
const Users = () => {
  const history = useHistory();
  const { sales } = useSelector((state) => state.sales);
  const { user, uid } = useSelector((state) => state.auth);
  const [salesUserID, setSalesUserID] = useState("");
  const salesUser = user;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { allUsers, totalPages, getUsersLoader } = useSelector(
    (state) => state.users
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userId, setUserID] = useState("");
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userSearchByName, setUserSearchByName] = useState("");
  const [csvmodal, setCSVModal] = useState(false);
  const [providersModal, setProvidersModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState(false);
  const [paginationStatus, setPaginationStatus] = useState("normal");
  const initialformData = {
    country: "",
    state: "",
    city: "",
    subCategories: "",
    cities: [],
  };
  const [formData, setFormData] = useState(initialformData);

  const handleSubmitProviderFilter = () => {
    if (formData.cities.length === 0 && !formData.subCategories) {
      toast.error("Please select at least one city or subcategory");
      return;
    }
    let payload = {};

    if (formData.cities.length > 0) {
      payload.cities = formData.cities;
    }
    if (formData.subCategories) {
      payload.occupation = formData.subCategories;
    }

    dispatch(filterProviders(payload, 1)).then(() => {
      setProvidersModal(false);
      setCurrentPage(1);
      setPaginationStatus("provider");
      setUserSearchByName("");
      setSalesUserID("");
    });
  };

  const toggle = () => setCSVModal(!csvmodal);
  const itemsPerPage = 10;

  const handleOnSubmit = () => {
    if (!userSearchByName) {
      toast.error("User Name not provided");
      return;
    }
    dispatch(userSearch(userSearchByName, 1)).then(() => {
      setCurrentPage(1);
      setPaginationStatus("search");
      setSalesUserID("");
      setFormData(initialformData);
    });
  };

  const handleClearFilters = () => {
    dispatch(getUsers(1, itemsPerPage)).then(() => {
      setCurrentPage(1);
      setPaginationStatus("normal");
      setUserSearchByName("");
      setSalesUserID("");
      setFormData(initialformData);
    });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleActivateModal = (id) => {
    setUserID(id);
    setMessage("Are you sure you want to activate this account?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    dispatch(activateUserAccount(userId, "active"));
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      if (paginationStatus === "normal") {
        dispatch(getUsers(newPage, itemsPerPage));
      } else if (paginationStatus === "search") {
        dispatch(userSearch(userSearchByName, newPage));
      } else if (paginationStatus === "filter") {
        const id = filterStatus ? salesUserID : uid;
        dispatch(filterUsers(id, newPage));
      } else if (paginationStatus === "provider") {
        let payload = {};

        if (formData.cities.length > 0) {
          payload.cities = formData.cities;
        }
        if (formData.subCategories) {
          payload.occupation = formData.subCategories;
        }
        dispatch(filterProviders(payload, newPage));
      }
    }
  };

  const modalGetaction = () => {
    if (userSearchByName !== "") {
      dispatch(userSearch(userSearchByName, 1)).then(() => {
        setCurrentPage(1);
        setSalesUserID("");
        setPaginationStatus("search");
        setFormData(initialformData);
      });
    } else {
      dispatch(getUsers(1, itemsPerPage)).then(() => {
        setCurrentPage(1);
        setPaginationStatus("normal");
        setSalesUserID("");
        setUserSearchByName("");
        setFormData(initialformData);
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  const copyUrl = () => {
    const url = window.location.origin;
    if (url.includes("tealpot-admin") || url.includes("localhost")) {
      const copyInvite = `https://staging.tealpot.com/auth/register?sales=${user?.code}`;
      navigator.clipboard.writeText(copyInvite);
      toast.success("Link copied to clipboard!");
    }
    if (url.includes("tealpot-admin-live") || url.includes("admin.tealpot")) {
      const copyInvite = `https://www.tealpot.com/auth/register?sales=${user?.code}`;
      navigator.clipboard.writeText(copyInvite);
      toast.success("Link copied to clipboard!");
    }
  };

  const OnBoardingFilter = () => {
    if (filterStatus) {
      dispatch(getUsers(1, itemsPerPage)).then(() => {
        setCurrentPage(1);
        setPaginationStatus("normal");
        setFilterStatus(false);
        setSalesUserID("");
        setUserSearchByName("");
        setFormData(initialformData);
      });
    } else {
      dispatch(filterUsers(uid, 1)).then(() => {
        setCurrentPage(1);
        setPaginationStatus("filter");
        setFilterStatus(true);
        setSalesUserID("");
        setFormData(initialformData);
      });
    }
  };

  useEffect(() => {
    if (salesUserID !== "") {
      if (salesUserID === "initial") {
        dispatch(getUsers(1, itemsPerPage)).then(() => {
          setCurrentPage(1);
          setPaginationStatus("normal");
          setFilterStatus(false);
          setSalesUserID("");
          setUserSearchByName("");
          setFormData(initialformData);
        });
      } else {
        dispatch(filterUsers(salesUserID, 1)).then(() => {
          setCurrentPage(1);
          setPaginationStatus("filter");
          setFilterStatus(true);
          setUserSearchByName("");
          setFormData(initialformData);
        });
      }
    }
  }, [salesUserID]);

  useEffect(() => {
    dispatch(getSales());
    dispatch(getUsers(1, itemsPerPage));
  }, []);

  return (
    <>
      <SuspendAccountModal
        modal={modal}
        toggle={toggleModal}
        userId={userId}
        page={currentPage}
        limit={itemsPerPage}
        modalGetaction={modalGetaction}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row flex-wrap">
                  <Col
                    className={`${
                      salesUser?.role === "sales" ? "col-lg-2" : "col-lg-2"
                    } col-12 text-center text-lg-left mb-3 mb-lg-0`}
                  >
                    <h3>Users</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row gap-x-10 justify-content-around align-items-center align-items-lg-start w-100">
                    <div>
                      <FormGroup className="w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={userSearchByName}
                          onChange={(e) => setUserSearchByName(e.target.value)}
                          className="py-2"
                        />
                        <small className="text-xs text-primary d-block text-center text-lg-left">
                          Search by ID, Email & Name
                        </small>
                      </FormGroup>
                    </div>
                    <div
                      className={`d-flex flex-wrap justify-content-start justify-content-lg-start ${
                        salesUser?.role === "sales" ? "w-max-100" : ""
                      }`}
                    >
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2"
                        disabled={getUsersLoader}
                        onClick={handleOnSubmit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 "
                        disabled={getUsersLoader || !userSearchByName}
                        onClick={handleClearFilters}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                      {user?.role !== "sales" && (
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 "
                          disabled={getUsersLoader}
                          onClick={() => setCSVModal(true)}
                          size="sm"
                        >
                          Export Users
                        </Button>
                      )}
                      {user?.role === "admin" && (
                        <div className="d-flex position-relative">
                          <Button
                            color="primary"
                            className="p-2 mx-2 my-2 "
                            onClick={() => setProvidersModal(true)}
                            size="sm"
                          >
                            Filter providers
                          </Button>
                          {paginationStatus === "provider" && (
                            <i
                              className="fas fa-times cancel-pill m-2"
                              onClick={handleClearFilters}
                            />
                          )}
                        </div>
                      )}
                      {user?.role === "sales" && (
                        <Button
                          color={`${filterStatus ? "danger" : "primary"}`}
                          className="p-2 mx-2 my-2"
                          disabled={getUsersLoader}
                          onClick={OnBoardingFilter}
                          size="sm"
                        >
                          {filterStatus
                            ? "Clear OnBoarding Filter"
                            : `OnBoard by ${salesUser?.firstName}`}
                        </Button>
                      )}
                      {user?.role === "sales" && (
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 "
                          onClick={() => copyUrl()}
                          size="sm"
                        >
                          Copy Invite Url
                        </Button>
                      )}
                      {user?.role !== "sales" && (
                        <div className="position-relative d-inline-block w-40 mx-2 my-2">
                          <Input
                            className="form-control"
                            name="select"
                            type="select"
                            value={salesUserID}
                            onChange={(e) => setSalesUserID(e.target.value)}
                          >
                            <option value="initial">
                              --Select OnBoard Filter--
                            </option>
                            {sales.map((user, index) => (
                              <option key={index} value={user?.id}>
                                {user?.email}
                              </option>
                            ))}
                          </Input>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">User Type</th>
                    <th scope="col">SSN/EIN</th>
                    {/* <th scope="col">Tax ID</th> */}
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    <th scope="col">Referred From</th>
                    {salesUser?.role !== "sales" && <th scope="col">Action</th>}
                  </tr>
                </thead>
                {!getUsersLoader ? (
                  <tbody>
                    {allUsers && allUsers.length > 0 ? (
                      allUsers?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.userId}</td>
                            <td>
                              {user?.firstName} {user?.lastName}
                            </td>
                            <td>
                              {user?.organization
                                ? "Organization"
                                : "Individual"}
                            </td>
                            <td>{user?.organization ? "SSN" : "EIN"}</td>
                            {/* <td>
                              {user?.businessId ? user.businessId : "N/A"}
                            </td> */}
                            <td>{formatTimestamp(user?.createdAt)}</td>
                            <td>{formatTimestamp(user?.updatedAt)}</td>
                            <td>{user?.email}</td>
                            <td>{user?.survey ? user?.survey : "N/A"}</td>
                            {salesUser?.role !== "sales" && (
                              <td>
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    history.push(
                                      `/admin/single-user-details/${user?.id}`
                                    );
                                  }}
                                >
                                  View
                                </Button>

                                {user?.status === "active" ? (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    style={{ width: "130px" }}
                                    onClick={() => {
                                      setUserID(user?.id);
                                      toggleModal();
                                    }}
                                  >
                                    Suspend Account
                                  </Button>
                                ) : (
                                  <Button
                                    size="sm"
                                    color="primary"
                                    style={{ width: "130px" }}
                                    onClick={() =>
                                      handleActivateModal(user?.id)
                                    }
                                  >
                                    Activate Account
                                  </Button>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No Users Found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      forcePage={currentPage - 1}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>

      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={userId}
        message={message}
        onConfirm={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      />
      <UserCSVModal modal={csvmodal} toggle={toggle} />
      <ProvidersModal
        formData={formData}
        setFormData={setFormData}
        paginationStatus={paginationStatus}
        modal={providersModal}
        toggle={() => setProvidersModal(!providersModal)}
        handleSubmit={handleSubmitProviderFilter}
        clearFilters={handleClearFilters}
      />
    </>
  );
};

export default Users;
