const initState = {
  allCategories: [],
  getCategoriesLoader: false,
  addCategoryLoader: false,
  deleteCategoryLoader: false,
  updateCategoryLoader: false,
  totalPages: null,
  allSubCategories: [],
  getSubCategoriesLoader: false,
  addSubCategoryLoader: false,
  deleteSubCategoryLoader: false,
  updateSubCategoryLoader: false,
  subCategoriesById: [],
  subCategoriestotalPages: null,
};

const categoriesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_CATEGORIES":
      return {
        ...state,
        allCategories: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_CATEGORIES_LOADER":
      return {
        ...state,
        getCategoriesLoader: payload,
      };
    case "ADD_CATEGORY_LOADER":
      return {
        ...state,
        addCategoryLoader: payload,
      };
    case "DELETE_CATEGORY_LOADER":
      return {
        ...state,
        deleteCategoryLoader: payload,
      };
    case "UPDATE_CATEGORY_LOADER":
      return {
        ...state,
        updateCategoryLoader: payload,
      };
    case "GET_CATEGORY_SEARCH_BY_TITLE":
      return {
        ...state,
        allCategories: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SUBCATEGORY_SEARCH_BY_TITLE":
      return {
        ...state,
        subCategoriesById: payload.data,
        subCategoriestotalPages: payload.totalPages,
      };
    case "GET_SUB_CATEGORIES":
      return {
        ...state,
        allSubCategories: payload,
        subCategoriestotalPages: payload.totalPages,
      };
    case "GET_SUB_CATEGORIES_LOADER":
      return {
        ...state,
        getSubCategoriesLoader: payload,
      };
    case "ADD_SUB_CATEGORY_LOADER":
      return {
        ...state,
        addSubCategoryLoader: payload,
      };
    case "DELETE_SUB_CATEGORY_LOADER":
      return {
        ...state,
        deleteSubCategoryLoader: payload,
      };
    case "UPDATE_SUB_CATEGORY_LOADER":
      return {
        ...state,
        updateSubCategoryLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default categoriesReducer;
