import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  getAllSubCategories,
  subCategorySearchByTitle,
} from "store/actions/categoriesAction";
import DropdownComponent from "components/DropdownComponent";
import {
  getCountryStates,
  getCities,
  getCountries,
} from "store/actions/countryAction";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Label,
  Col,
  Badge,
} from "reactstrap";

const ProvidersModal = ({
  modal,
  toggle,
  formData,
  setFormData,
  handleSubmit,
  paginationStatus,
  clearFilters,
}) => {
  const dispatch = useDispatch();

  const { getSubCategoriesLoader, subCategoriestotalPages } = useSelector(
    (state) => state.category
  );

  const {
    countriesList: countries,
    states,
    cities,
    countryLoader,
    stateLoader,
    cityLoader,
  } = useSelector((state) => state.countries);

  const { getUsersLoader } = useSelector((state) => state.users);

  const [subCategoriesOptions, setSubCategoryOptions] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeBtn, setActiveBtn] = useState("");
  const [page, setPage] = useState({
    subCategories: 1,
  });

  const formatData = (data) => {
    return data.map((item) => ({
      value: item._id,
      label: item.name,
    }));
  };

  const toggleDropdown = useCallback((dropdownName) => {
    setActiveDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  }, []);

  useEffect(() => {
    dispatch(getAllSubCategories(1)).then((res) => {
      const formatedCategories = formatData(res);
      setSubCategoryOptions(formatedCategories);
    });
    dispatch(getCountries());
  }, []);

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className="pb-0 pt-2 px-2"></ModalHeader>
      <ModalBody className="pt-0">
        <h2 className="text-center mb-4">Filter Providers</h2>
        <div className="d-flex flex-column align-items-center">
          <Col className="p-0">
            <Label className="font-weight-bold">Profession</Label>

            {/* Sub Categories */}
            <DropdownComponent
              list={subCategoriesOptions}
              value={
                formData?.subCategories
                  ? subCategoriesOptions?.find(
                      (item) => item?.value === formData?.subCategories
                    )?.label || ""
                  : ""
              }
              onChange={(item) => {
                setFormData({
                  ...formData,
                  subCategories: item?.value,
                });
              }}
              placeholder="-- Select Profession --"
              isOpen={activeDropdown === "subCategories"}
              toggleDropdown={() => toggleDropdown("subCategories")}
              canPaginate={
                subCategoriesOptions.length > 0 &&
                page?.subCategories < subCategoriestotalPages
              }
              onSearch={debounce((query) => {
                dispatch(subCategorySearchByTitle(query)).then((res) => {
                  const formatedSubCategories = formatData(res);
                  setSubCategoryOptions(formatedSubCategories);
                });
              }, 1000)}
              clearSearch={() => {
                dispatch(getAllSubCategories(1)).then((res) => {
                  const formatedSubCategories = formatData(res);
                  setSubCategoryOptions(formatedSubCategories);
                });
              }}
              onLoadMore={() => {
                const currentpage = page?.subCategories + 1;
                setPage((page) => ({ ...page, subCategories: currentpage }));
                dispatch(getAllSubCategories(currentpage)).then((res) => {
                  const formatedSubCategories = formatData(res);
                  setSubCategoryOptions([
                    ...subCategoriesOptions,
                    ...formatedSubCategories,
                  ]);
                });
              }}
              isLoading={getSubCategoriesLoader}
            />
          </Col>
          <Col className="p-0">
            <Label className="font-weight-bold">Location</Label>

            {/* Country */}
            <DropdownComponent
              list={countries}
              value={
                formData?.country
                  ? countries?.find(
                      (item) => item?.countryCode === formData?.country
                    )?.label || ""
                  : ""
              }
              onChange={(item) => {
                setFormData({
                  ...formData,
                  country: item.countryCode,
                  state: "",
                  city: "",
                });

                dispatch(getCountryStates({ country: item.countryCode }));
              }}
              placeholder="-- Select Country --"
              isOpen={activeDropdown === "country"}
              toggleDropdown={() => toggleDropdown("country")}
              isLoading={countryLoader}
            />

            {/* State */}
            <DropdownComponent
              list={states}
              value={
                formData.state
                  ? states?.find((item) => item?.value === formData?.state)
                      ?.value || ""
                  : ""
              }
              onChange={(item) => {
                setFormData({ ...formData, state: item.value, city: "" });
                dispatch(getCities({ state: item.id }));
              }}
              placeholder="-- Select State/Region --"
              isOpen={activeDropdown === "state"}
              toggleDropdown={() => {
                formData?.country
                  ? toggleDropdown("state")
                  : toast.warning("Please select country first");
              }}
              isLoading={stateLoader}
            />

            {/* City */}
            <DropdownComponent
              list={cities}
              value={formData?.city}
              onChange={(item) => {
                setFormData((prev) => ({
                  ...prev,
                  city: item.value,
                  cities: prev.cities.includes(item.value)
                    ? prev.cities
                    : [...prev.cities, item.value],
                }));
              }}
              placeholder="-- Select City --"
              isOpen={activeDropdown === "city"}
              toggleDropdown={() => {
                formData?.state
                  ? toggleDropdown("city")
                  : toast.warning("Please select state first");
              }}
              isLoading={cityLoader}
            />
          </Col>

          {formData?.cities && formData?.cities?.length > 0 && (
            <div
              className="d-flex flex-wrap pb-4 w-100"
              style={{ gap: "10px" }}
            >
              {formData?.cities.map((item) => {
                return (
                  <Badge color="light" className="p-2 border position-relative">
                    {item}
                    <i
                      className="fas fa-times cancel-pill"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          cities: prev.cities.filter((city) => city !== item),
                        }));
                      }}
                    />
                  </Badge>
                );
              })}
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center w-full ">
          <Button
            color="primary"
            onClick={() => {
              handleSubmit();
              setActiveBtn("filter");
            }}
            disabled={getUsersLoader}
          >
            {getUsersLoader && activeBtn === "filter" ? (
              <Spinner size="sm" />
            ) : (
              "Apply Filter"
            )}
          </Button>
          {paginationStatus === "provider" && (
            <Button
              color="primary"
              onClick={() => {
                clearFilters();
                setActiveBtn("cancel");
              }}
              disabled={getUsersLoader}
            >
              {getUsersLoader && activeBtn === "cancel" ? (
                <Spinner size="sm" />
              ) : (
                "Clear Filter"
              )}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProvidersModal;
