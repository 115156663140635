/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const GET_ALL_CATEGORIES = "category";
const ADD_CATEGORY = "category";
const DELETE_CATEGORY = "category";
const UPDATE_CATEGORY = "category";
const GET_SEARCH_BY_TITLE = "category/search";
const GET_ALL_SUB_CATEGORIES = "category/sub-category";
const ADD_SUB_CATEGORY = "category/sub-category";
const DELETE_SUB_CATEGORY = "category/sub-category";
const UPDATE_SUB_CATEGORY = "category/sub-category";
const SUB_CATEGORIES_SEARCH = "/category/sub-category/search";

export default {
  getCategories(page, limit) {
    if (page && limit) {
      return Repository.get(
        `${GET_ALL_CATEGORIES}?page=${page}&limit=${limit}`
      );
    } else {
      return Repository.get(GET_ALL_CATEGORIES);
    }
  },

  addCategory(payload) {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("file", payload.image);
    payload?.taxCodes?.forEach((str, index) => {
      formData.append(`taxCodes[${index}]`, str);
    });
    return Repository.post(`${ADD_CATEGORY}`, formData);
  },
  deleteCategory(id) {
    return Repository.delete(`${DELETE_CATEGORY}/${id}`);
  },
  updateCategory(id, payload) {
    return Repository.put(`${UPDATE_CATEGORY}/${id}`, payload);
  },
  categorySearchByTitle(title) {
    const requestBody = {
      text: title,
    };
    return Repository.post(`${GET_SEARCH_BY_TITLE}`, requestBody);
  },
  getSubCategories(page) {
    if (page) {
      return Repository.get(
        `${GET_ALL_SUB_CATEGORIES}?page=${page}&limit=10`
      );
    } else {
      return Repository.get(`${GET_ALL_SUB_CATEGORIES}`);
    }

  },
  getSubCategoriesByCategory(page, categoryId) {
    return Repository.get(
      `${GET_ALL_SUB_CATEGORIES}/${categoryId}?page=${page}&limit=10`
    );
  },
  subCategorySearchByTitle(title) {
    const requestBody = {
      text: title,
    };
    return Repository.post(`${SUB_CATEGORIES_SEARCH}`, requestBody);
  },
  
  addSubCategory(payload) {
    const requestBody = {
      categoryId: payload.id,
      subCategories: payload.subcategories,
    };
    return Repository.post(`${ADD_SUB_CATEGORY}`, requestBody);
  },
  deleteSubCategory(id) {
    return Repository.delete(`${DELETE_SUB_CATEGORY}/${id}`);
  },
  updateSubCategory(id, payload) {
    return Repository.put(`${UPDATE_SUB_CATEGORY}/${id}`, payload);
  },
  // addTaxCode(payload) {
  //   return Repository.post(`tax-code`, payload);
  // },
  // getCodes(ID) {
  //   return Repository.get(`tax-code`, ID);
  // },
};
