import React from "react";
import {
  useLocation,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useSelector } from "react-redux";

const roleBasedRoutes = {
  admin: "all",
  resolutionTeam: ["/admin/index", "/admin/orders", "/admin/resolution"],
  supportPerson: [
    "/admin/index",
    "/admin/support-team",
    "/admin/tickets",
    "/admin/ticket-details/:id",
  ],
  sales: ["/admin/users"],
  seo_admin: ["/admin/blogs", "/admin/addBlog", "/admin/editBlog/:id"],
};

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const userRole = user?.role;

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  // const isAllowedRoute = (path) => {
  //   if (roleBasedRoutes[userRole] === "all") return true;
  //   return roleBasedRoutes[userRole]?.includes(path);
  // };

  const isAllowedRoute = (path) => {
    if (roleBasedRoutes[userRole] === "all") return true;

    return roleBasedRoutes[userRole]?.some((route) => {
      if (route.includes(":")) {
        const regex = new RegExp(`^${route.replace(/:[^/]+/g, "([^/]+)")}$`);
        return regex.test(path);
      }
      return route === path;
    });
  };

  React.useEffect(() => {
    if (!userRole) return;
    if (!isAllowedRoute(location.pathname)) {
      const defaultRoute = roleBasedRoutes[userRole]?.[0] || "/admin/index";
      console.log(`Redirecting from ${location.pathname} to ${defaultRoute}`);
      history.replace(defaultRoute);
    }
  }, [location.pathname, userRole, history]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (isAllowedRoute(prop.layout + prop.path)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      }
      return null;
    });
  };

  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
