import OnlyHeader from "components/Headers/OnlyHeader";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { FaLink } from "react-icons/fa6";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { getSingleBlogs, updateBlog } from "store/actions/blogsAction";
import {
  getCategoriesAction,
  getSubCategoriesAction,
} from "store/actions/categoriesAction";
import ImagePicker from "components/ImagePicker";

const EditBlog = () => {
  const { allSubCategories, allCategories } = useSelector(
    (state) => state.category
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [selectedValue, setSelectedValues] = useState([]);
  const {
    mediaUrl,
    updateBlogLoader,
    singleBlog: { blog },
  } = useSelector((state) => state.blogs);

  const [blogData, setBlogData] = useState({
    title: "",
    content: "",
    category: "",
    file: "",
    metaTitle: "",
    metaDescription: "",
    altText: "",
    blogUrl: "",
    // scheduleTime: "",
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);

  const domain = () => {
    const url = window.location.href;
    let domainUrl;
    if (url.includes("tealpot-admin.web.app") || url.includes("localhost")) {
      domainUrl = "staging.tealpot.com/blogs/detail";
    } else if (url.includes("tealpot-admin-live.web.app") || url.includes("admin.tealpot.com")) {
      domainUrl = "tealpot.com/blogs/detail";
    }
    return domainUrl;
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const handleBlogDataChange = (e) => {
    e.preventDefault();
    const { name, value, files } = e.target;
    if (name === "featuredImgURL" || name === "getMediaURL") {
      setBlogData({
        ...blogData,
        [name]: files[0],
      });
    } else if (name === "blogUrl") {
      setBlogData((prevData) => ({
        ...prevData,
        blogUrl: value.replace(/[^a-zA-Z]/g, ""),
      }));
    } else {
      setBlogData({
        ...blogData,
        [name]: value,
      });
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setBlogData((prevData) => ({
      ...prevData,
      category: selectedOption.value,
    }));
    setSelectedValues([]);
    dispatch(getSubCategoriesAction(selectedOption.value));
  };

  const initialCategory = allCategories.find(
    (category) => category._id === blogData.category
  );
  const initialValue = initialCategory
    ? { value: initialCategory._id, label: initialCategory.name }
    : null;

  const onSelect = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const convertToUnixTime = (timestamp) => {
    if (!timestamp) return null;
    const dateObject = new Date(timestamp);
    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid timestamp format");
    }
    return Math.floor(dateObject.getTime() / 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const minTitleLength = 40;

    if (blogData.title.trim().length < minTitleLength) {
      toast.error("Title should be minimum of 40 charachters");
      return;
    }

    if (
      !blogData.title ||
      !blogData.content ||
      !blogData.category ||
      !blogData.file ||
      !blogData.altText ||
      !blogData.metaTitle ||
      !blogData.metaDescription ||
      !id
      // ||
      // selectedValue.length === 0
    ) {
      toast.error("Please fill all the required fields");
      return;
    }

    const temp = {
      ...blogData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    const payload = {
      title: temp.title,
      description: temp.content,
      category: temp.category,
      subCategory: selectedValue?.map((item) => item?._id),
      file: temp.file,
      metaTitle: temp.metaTitle,
      metaDescription: temp.metaDescription,
      altText: temp.altText,
      blogUrl: temp.blogUrl
        ? `tealpot.com/blogs/detail/${id}/${temp.blogUrl}`
        : `tealpot.com/blogs/detail/${id}`,
      // scheduleTime: convertToUnixTime(temp.scheduleTime),
    };

    dispatch(
      updateBlog(id, payload, () => {
        history.push("/admin/blogs");
        toast.success("Blog Updated Successfully");
      })
    );
  };

  const extractText = (name) => {
    const parts = name.split("detail/")[1]?.split("/") || [];
    return parts.length > 1 ? parts[1] : "";
  };

  useEffect(() => {
    if (mediaUrl) {
      setBlogData({
        ...blogData,
        mediaFiles: [...blogData.mediaFiles, mediaUrl],
      });
    }
  }, [mediaUrl]);

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleBlogs(id));
  }, [id]);

  useEffect(() => {
    if (blog?.description) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(blog?.description))
        )
      );

      setBlogData({
        title: blog?.title,
        category: blog?.category?._id,
        content: blog?.description,
        file: blog?.image,
        metaTitle: blog?.metaTitle,
        metaDescription: blog?.metaDescription,
        altText: blog?.altText,
        blogUrl: extractText(blog?.blogUrl),
        // scheduleTime: blog?.scheduleTime,
      });

      setSelectedValues(blog?.subCategory);
    }
  }, [blog]);
  const embedVideoCallBack = (iframeHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = iframeHtml.trim();
    const iframeElement = tempDiv.querySelector("iframe");
    if (iframeElement) {
      const src = iframeElement.getAttribute("src");
      const cleanSrc = src.split("?")[0];
      return cleanSrc;
    } else {
      return null;
    }
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto ">Title</Label>
                  <Input
                    name="title"
                    className="form-controll_title"
                    value={blogData.title}
                    required
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                  {blogData.title.trim().length < 40 && (
                    <h6
                      className="text-muted
                    "
                    >
                      Title must be at least 40 characters long.
                    </h6>
                  )}
                </Col>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto">Category</Label>
                  <Select
                    name="category"
                    value={initialValue}
                    options={allCategories.map((category) => ({
                      value: category._id,
                      label: category.name,
                    }))}
                    onChange={handleCategoryChange}
                    required
                  />
                </Col>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto ">Sub Categories</Label>
                  <Multiselect
                    options={allSubCategories}
                    selectedValues={selectedValue}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                    required
                  />
                </Col>
                {/* <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto ">Schedule Time</Label>
                  <Input
                    name="scheduleTime"
                    type="datetime-local"
                    value={blogData.scheduleTime}
                    min={new Date().toISOString().split("T")[0] + "T00:00"}
                    required
                    disabled={new Date(blogData.scheduleTime) < new Date()}
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                </Col> */}
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto ">Blog URL</Label>
                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex border rounded overflow-hidden w-100">
                      <Input
                        className="border-0 rounded-0"
                        value={`${domain()}/${id}/`}
                        readOnly
                        disable
                      />
                      <Input
                        className="border-0"
                        name="blogUrl"
                        value={blogData?.blogUrl}
                        onChange={(e) => {
                          handleBlogDataChange(e);
                        }}
                      />
                    </div>
                    <Button
                      color="primary"
                      className="ml-1 p-2"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          blogData?.blogUrl
                            ? `${domain()}/${id}/${blogData?.blogUrl}`
                            : `${domain()}/${id}`
                        );
                        toast.success("Copied to clipboard");
                      }}
                    >
                      <FaLink size={20} />
                    </Button>
                  </div>
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto ">Meta Title</Label>
                  <Input
                    name="metaTitle"
                    className="mb-3"
                    value={blogData.metaTitle}
                    required
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                </Col>
                <Col md="12" className="my-2">
                  <Label className="mb-0 my-auto ">Meta Description</Label>
                  <Input
                    type="textarea"
                    rows={5}
                    name="metaDescription"
                    value={blogData.metaDescription}
                    required
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                </Col>

                <div className="mx-3 mt-2 mb-2">
                  <ImagePicker
                    image={blogData.file || blogData.image}
                    className="red-icon"
                    onImageChange={(newFile) => {
                      setBlogData((prevData) => ({
                        ...prevData,
                        file: newFile,
                      }));
                    }}
                  />
                  <Input
                    name="altText"
                    className="mt-1"
                    placeholder="Type Alt Text"
                    value={blogData.altText}
                    required
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                </div>
                <Col md={12} className="mt-3">
                  {/* <Editor
                    editorState={editorState}
                    editorStyle={{
                      border: "2px solid #C0C0C0",
                    }}
                    toolbar={{
                      embedded: {
                        embedCallback: embedVideoCallBack,
                      },

                      fontFamily: {
                        options: [
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                          "Barlow",
                        ],
                      },
                    }}
                    onEditorStateChange={handleEditorChange}
                  /> */}
                  <Editor
                    editorState={editorState}
                    editorStyle={{
                      border: "2px solid #C0C0C0",
                    }}
                    toolbar={{
                      embedded: {
                        embedCallback: embedVideoCallBack,
                        defaultSize: {
                          height: "auto",
                          width: "auto",
                        },
                      },
                      fontFamily: {
                        options: [
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                          "Barlow",
                        ],
                      },
                    }}
                    onEditorStateChange={handleEditorChange}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  type="button"
                  color="primary"
                  className="mt-3"
                  disabled={updateBlogLoader}
                  onClick={handleSubmit}
                >
                  {updateBlogLoader ? (
                    <Spinner
                      className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                      size="sm"
                      color="white"
                    />
                  ) : (
                    "SAVE CHANGES"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};
export default EditBlog;
