import Repository from "./Repository";
const GET_ALL = "blog/";
const GET_SEARCH_BY_TITLE = "blog/searchByTitle";
const ADD_BLOG = "blog/";
const DELETE_BLOG = "blog/";
const UPDATE_BLOG = "blog/";
const GET_SINGLE_BLOG = "blog/";
const CREATE_BLOG = "blog/empty";

export default {
  getBlogs(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  blogSearchByTitle(title, page) {
    return Repository.get(
      `${GET_SEARCH_BY_TITLE}/${title}?page=${page}&limit=10`
    );
  },
  singleBlog(id) {
    return Repository.get(`${GET_SINGLE_BLOG}/${id}`);
  },
  createBlog() {
    return Repository.get(`${CREATE_BLOG}`);
  },

  addBlog(payload) {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("category", payload.category);
    formData.append("file", payload.file);
    payload?.subCategory?.forEach((str, index) => {
      formData.append(`subCategory[${index}]`, str);
    });
    formData.append("metaTitle", payload.metaTitle);
    formData.append("metaDescription", payload.metaDescription);
    formData.append("altText", payload.altText);
    formData.append("blogUrl", payload.blogUrl);
    formData.append("scheduleTime", payload.scheduleTime);
    return Repository.post(`${ADD_BLOG}/${payload.id}`, formData);
  },
  updateBlog(id, payload) {
    console.log(payload);
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("category", payload.category);
    formData.append("file", payload.file);
    payload?.subCategory?.forEach((str, index) => {
      formData.append(`subCategory[${index}]`, str);
    });
    formData.append("metaTitle", payload.metaTitle);
    formData.append("metaDescription", payload.metaDescription);
    formData.append("altText", payload.altText);
    formData.append("blogUrl", payload.blogUrl);
    formData.append("scheduleTime", payload.scheduleTime);
    return Repository.put(`${UPDATE_BLOG}/${id}`, formData);
  },
  deleteBlog(id) {
    return Repository.delete(`${DELETE_BLOG}/${id}`);
  },
};
